<template>
  <div id="config-user-account">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="config-user-account-container mt-4">
      <b-row>
        <b-col class="tab-nav d-sm-none d-flex">
          <Tab
            name="Identidad"
            :selected="displayedCard == 'identity'"
            @onClick="displayedCard = 'identity'"
          />
          <Tab
            name="Perfil de empresa"
            :selected="displayedCard == 'business'"
            @onClick="displayedCard = 'business'"
          />
          <Tab
            name="Contraseña"
            :selected="displayedCard == 'password'"
            @onClick="displayedCard = 'password'"
          />
        </b-col>
        <b-col class="d-none d-sm-block">
          <b-card class="side-card">
            <MenuItem
              title="Identidad"
              icon="user.svg"
              @onclickMenuItem="displayedCard = 'identity'"
            />
            <MenuItem
              title="Perfil de empresa"
              icon="flag.svg"
              @onclickMenuItem="displayedCard = 'business'"
            />
            <MenuItem
              title="Contraseña"
              icon="lock.svg"
              @onclickMenuItem="displayedCard = 'password'"
            />
          </b-card>
        </b-col>
        <b-col cols="12" sm="8">
          <change-name-card
            v-if="displayedCard == 'identity'"
          />
          <update-user-business-card
            :user="loggedUser"
            :extendedUser="loggedUser.getBusiness"
            v-if="displayedCard == 'business'"
          />
          <config-user-card
            header="Inicio de sesión"
            media-img="icon_key"
            media-title="Cambiar contraseña"
            media-text="Se recomienda usar una contraseña segura que no uses para ningún otro sitio"
            button-text="Cambiar"
            v-if="displayedCard=='password'"
            @onClickButton="password.showEditPasswordForm = !password.showEditPasswordForm"
          >
            <template v-slot:config-user-card-body v-if="password.showEditPasswordForm">
              <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onChangePassword)" autocomplete="off" novalidate>
                <hr>
                  <b-row>
                    <b-col cols="12" sm="6">
                      <validation-provider name="contraseña actual" rules="required" v-slot="validationContext">
                        <b-form-group id="current-password" label-for="input-username">
                          <b-form-input
                            id="current-password"
                            name="current-password"
                            type="password"
                            v-model="password.changePassword.currentPassword"
                            :state="getValidationState(validationContext)"
                            placeholder="Contraseña actual"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" sm="6">
                      <validation-provider name="contraseña" v-slot="validationContext" vid="confirmation" rules="required|password">
                        <b-form-group id="input-password" label-for="input-password">
                          <b-form-input
                            id="input-password"
                            name="input-password"
                            type="password"
                            v-model="password.changePassword.newPassword"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-password"
                            placeholder="Contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" sm="6">
                      <validation-provider name="confirma tu contraseña" rules="required|confirmed:confirmation" v-slot="validationContext">
                        <b-form-group id="input-repeated-password" label-for="input-repeated-password">
                          <b-form-input
                            id="input-repeated-password"
                            name="input-repeated-password"
                            type="password"
                            v-model="password.repeatedPassword"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-repeated-password"
                            placeholder="Confirma tu contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                <hr>
                  <b-row>
                    <b-col cols="12">
                      <div class="text-center">
                        <b-button block type="submit" variant="primary">
                          Guardar cambios
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </template>
          </config-user-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Tab from '@/components/Tab/Tab.vue'
import ConfigUserCard from '@/components/Card/ConfigUserCard'
import ChangeNameCard from '@/components/Card/ChangeNameCard'
import UpdateUserBusinessCard from '@/components/Card/UpdateUserBusinessCard'
import MenuItem from '@/components/Config/MenuItem'

export default {
  name: 'ConfigUSerAccount',
  components: {
    Header,
    Tab,
    ConfigUserCard,
    ChangeNameCard,
    UpdateUserBusinessCard,
    MenuItem
  },
  data () {
    return {
      displayedCard: 'identity',
      password: {
        showEditPasswordForm: false,
        changePassword: {
          currentPassword: null,
          newPassword: null
        },
        repeatedPassword: null
      }
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    onChangePassword () {
      this.lockScreen()
      this.$store.dispatch('updateUserPassword', this.password.changePassword)
        .then(() => {
          this.password.showEditPasswordForm = false
          this.password.repeatedPassword = null
          this.password.changePassword = {
            currentPassword: null,
            newPassword: null
          }
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>
<style lang="less">
  @import 'config.less';
  .tab-nav {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>

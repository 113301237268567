<template>
  <b-card
    header="Identidad"
    header-text-variant="white"
    align="left"
    class="avify-config-user-card"
  >
    <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onChangeIdentity)"
        autocomplete="off"
        novalidate
      >
        <b-row>
          <b-col cols=6>
            <validation-provider
              name="nombre" rules="required" v-slot="validationContext">
              <b-form-group id="name" label-for="input-name">
                <b-form-input
                  id="name"
                  name="name"
                  v-model="editableUser.firstName"
                  :state="getValidationState(validationContext)"
                  placeholder="Nombre"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols=6>
            <validation-provider
              name="apellido" rules="required" v-slot="validationContext">
              <b-form-group id="apellido" label-for="input-apellido">
                <b-form-input
                  id="apellido"
                  name="apellido"
                  v-model="editableUser.lastName"
                  :state="getValidationState(validationContext)"
                  placeholder="Apellido"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" offset-md="8" md="4">
            <div class="text-center">
              <b-button block type="submit" variant="primary">
                Guardar cambios
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import EditableUserFactory from '@/domain/services/EditableUserFactory'

export default {
  name: 'ChangeNameCard',
  computed: {
    editableUser () {
      return EditableUserFactory.getEditableUser(this.$store.getters.loggedUser)
    }
  },
  methods: {
    onChangeIdentity () {
      this.lockScreen()
      this.$store.dispatch('updateUser', this.editableUser)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<template>
  <b-card
    header="Perfil de empresa"
    header-text-variant="white"
    align="left"
    class="avify-config-user-card"
  >
    <validation-observer ref="updateUserBusinessForm" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onChangeUserBusiness)"
        autocomplete="off"
        novalidate
      >
        <b-card no-body class="private-image-card">
          <b-card-header>
            <img
              v-if="extendedUser.hasCover"
              :src="baseUrl + extendedUser.getCover.path"
              alt="cover-image"
              class="h-100 w-100 mt-0"
              height="176"
            />
            <img
              v-else
              src="../../assets/img/profile-background.png"
              alt="cover-image"
              class="h-100 w-100 mt-0"
              height="176"
            />
            <span v-if="editIcon" class="edit-card-info-image" @click="openCoverModal"></span>
            <modal-component
              id="coverModal"
              title="Sube una imagen"
              size="lg"
              hideFooter
            >
              <image-upload
                @onCancelUploadFile="closeCoverModal"
                @onChangeUploadFile="closeCoverModal"
                :coverImgUrl="getCoverUrl"
                dispatch="uploadBusinessCoverImage"
              ></image-upload>
            </modal-component>
          </b-card-header>
          <b-card-body>
            <div class="avatar-container">
              <img
                v-if="extendedUser.hasAvatar"
                :src="baseUrl + extendedUser.getAvatar.path"
                alt="avatar"
                class="user-avatar img-thumbnail img-fluid"
              />
              <img
                v-else src="/img/default-user-avatar.svg"
                alt="avatar"
                class="user-avatar img-thumbnail img-fluid"
              />
              <span
                v-if="editIcon"
                class="change-avatar-image edit-card-info-image"
                @click="openAvatarModal">
              </span>
              <modal-component
                id="avatarModal"
                title="Sube una imagen"
                hideFooter
              >
                <avatar-image-upload
                  @onCancelUploadAvatarFile="closeAvatarModal"
                  @onChangeUploadAvatarFile="closeAvatarModal"
                  :avatarImgUrl="getAvatarUrl"
                  dispatch="uploadBusinessAvatarImage"
                ></avatar-image-upload>
              </modal-component>
            </div>
          </b-card-body>
        </b-card>
        <b-row>
          <b-col cols="6">
            <validation-provider
              name="nombre de la empresa"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-group id="business-name" label-for="input-business-name">
                <b-form-input
                  id="business-name"
                  name="business-name"
                  v-model="extendedUser.name"
                  :state="getValidationState(validationContext)"
                  placeholder="Nombre de la empresa"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              name="cif"
              rules="cif|required"
              v-slot="validationContext"
            >
              <b-form-group id="cif" label-for="input-cif">
                <b-form-input
                  id="cif"
                  name="cif"
                  v-model="extendedUser.cif"
                  :state="getValidationState(validationContext)"
                  placeholder="CIF"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              name="página web"
              rules="url"
              v-slot="validationContext"
            >
              <b-form-group id="website" label-for="input-website">
                <b-form-input
                  id="website"
                  name="website"
                  v-model="extendedUser.web"
                  :state="getValidationState(validationContext)"
                  placeholder="Página Web"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <validation-provider
              name="prefijo"
              :rules="{ required: true, prefix: true }"
              v-slot="validationContext"
            >
              <b-form-group id="phone-prefix" label-for="input-phone-prefix">
                <b-form-input
                  id="phone-prefix"
                  maxlength="4"
                  name="phone-prefix"
                  v-model="extendedUser.phoneNumber.prefix"
                  :state="getValidationState(validationContext)"
                  placeholder="Prefijo"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              name="phone-number"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-group id="phone-number" label-for="input-phone-number">
                <b-form-input
                  id="phone-number"
                  name="phone-number"
                  v-model="extendedUser.phoneNumber.number"
                  :state="getValidationState(validationContext)"
                  placeholder="Número de teléfono"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="input-sectors" label-for="input-sectors">
              <ProfessionalSkillSelect
                  title="Sectores"
                  placeholder="Selecciona uno o varios sectores"
                  :options="sectorOptions"
                  :suggestions="true"
                  suggestionItem="sector"
                  :selectedOptions="extendedUser.sectors"
                  eventName="removeSector"
                  @onRemoveSector="onRemoveSector"
                  addEventName="addNewSector"
                  @addNewSector="onAddSector"
                  :are-options-loaded="sectorOptions.length === 0"
              />            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Dirección</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <validation-provider
              name="dirección"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-group id="address" label-for="input-address">
                <b-form-input
                  id="address"
                  name="address"
                  v-model="extendedUser.address"
                  :state="getValidationState(validationContext)"
                  placeholder="Dirección"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              name="código postal"
              rules="required"
              v-slot="validationContext"
            >
              <b-form-group id="zipcode" label-for="input-zipcode">
                <b-form-input
                  id="zipcode"
                  name="zipcode"
                  v-model="extendedUser.zipcode"
                  :state="getValidationState(validationContext)"
                  placeholder="Código postal"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="ciudad"
              v-slot="validationContext"
              vid="business-city"
              rules="required">
              <b-form-group id="business-city" label-for="input-business-city">
                <GooglePlaces
                  :value="value"
                  @selected="onChangeGoogleData"
                  @keypress="onKeyPressGoogle"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Perfiles sociales</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              name="perfil de Facebook"
              rules="url"
              v-slot="validationContext"
            >
              <b-form-group id="facebook" label-for="input-facebook">
                <b-form-input
                  id="facebook"
                  name="facebook"
                  v-model="extendedUser.facebookProfile"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil de Facebook"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              name="perfil de Instagram"
              rules="url"
              v-slot="validationContext"
            >
              <b-form-group id="instagram" label-for="input-instagram">
                <b-form-input
                  id="instagram"
                  name="instagram"
                  v-model="extendedUser.instagramProfile"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil de Instagram"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              name="perfil de Twitter"
              rules="url"
              v-slot="validationContext"
            >
              <b-form-group id="twitter" label-for="input-twitter">
                <b-form-input
                  id="twitter"
                  name="twitter"
                  v-model="extendedUser.twitterProfile"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil de Twitter"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              name="perfil de LinedIn"
              rules="url"
              v-slot="validationContext"
            >
              <b-form-group id="linkedIn" label-for="input-linkedIn">
                <b-form-input
                  id="linkedIn"
                  name="linkedIn"
                  v-model="extendedUser.linkedInProfile"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil de LinkedIn"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" offset="8" sm="4">
            <div class="text-center">
              <b-button block type="submit" variant="primary">
                Guardar cambios
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import ImageUpload from '../ImageUpload/ImageUpload'
import AvatarImageUpload from '../ImageUpload/AvatarImageUpload'
import ModalComponent from '../Modal/ModalComponent'
import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect'
import EditableUserFactory from '@/domain/services/EditableUserFactory'
import LocationFactory from '@/domain/services/LocationFactory'
import GooglePlaces from '../Places/GooglePlaces.vue'

export default {
  name: 'UpdateUserBusinessCard',
  components: {
    ModalComponent,
    ImageUpload,
    AvatarImageUpload,
    ProfessionalSkillSelect,
    GooglePlaces
  },
  data () {
    return {
      value: this.extendedUser.getFormattedLocation,
      editCoverImage: false,
      editAvatarImage: false,
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  props: {
    user: Object,
    extendedUser: Object,
    modalId: String,
    editIcon: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.$store.dispatch('getSectors')
    if (this.extendedUser.hasLocation) {
      this.value = this.extendedUser.getFormattedLocation
    }
  },
  computed: {
    sectorOptions () {
      return this.$store.getters.getSectorsList
    },
    getAvatarUrl () {
      if (this.extendedUser.getAvatar) {
        return this.baseUrl + this.extendedUser.getAvatar.path
      }

      return '/img/default-user-avatar.svg'
    },
    getCoverUrl () {
      if (this.extendedUser.getCover) {
        return this.baseUrl + this.extendedUser.getCover.path
      }

      return '/img/profile-background.png'
    }
  },
  methods: {
    onChangeGoogleData (evt) {
      const location = LocationFactory.getLocationByGoogle(evt)
      this.selectedCity = evt
      this.value = this.getFormattedGoogleLocationFromHit(location)
      this.extendedUser.location = location
    },
    getFormattedGoogleLocationFromHit (hit) {
      return [location.name, location.administrative, location.country].join(', ')
    },
    onKeyPressGoogle () {
      this.selectedCity = null
      this.extendedUser.city = null
      this.extendedUser.province = null
      this.extendedUser.country = null
      this.value = ''
    },
    onAddSector (sector) {
      const exists = this.extendedUser.getSectors.findIndex(
        item => item.id === sector.id
      )
      if (sector.id !== '' && exists === -1) {
        this.extendedUser.getSectors.push(sector)
      }
    },
    onRemoveSector (id) {
      this.extendedUser.getSectors.forEach((sector, index) => {
        if (sector.id === id) {
          this.extendedUser.getSectors.splice(index, 1)
        }
      })
    },
    onClickEditProfile () {
      this.$bvModal.show('edit-profile')
    },
    onChangeUserBusiness () {
      const data = this.user
      data.business = this.extendedUser
      this.lockScreen()

      this.$store.dispatch('updateUser', EditableUserFactory.getEditableUser(data))
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
        })
    },
    openAvatarModal () {
      this.$bvModal.show('avatarModal')
    },
    openCoverModal () {
      this.$bvModal.show('coverModal')
    },
    closeAvatarModal () {
      this.$bvModal.hide('avatarModal')
    },
    closeCoverModal () {
      this.$bvModal.hide('coverModal')
    }
  }
}
</script>
<style lang="less">
  @import 'updateUserBusinessCard.less';
</style>
